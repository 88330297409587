import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"

const WelcomeTitle = () => {
  return (
    <CenterContainer>
      <h2>Introducting</h2>
      <h1>Show StopWatch</h1>

      <p3>all your timing needs in one app</p3>
      <div>
        <br />
        <Button to="/about">learn more</Button>
        <Button to="https://apps.apple.com/us/app/show-stopwatch/id1211287819">
          AppStore
        </Button>
      </div>
    </CenterContainer>
  )
}

export default WelcomeTitle

const CenterContainer = styled.div`
  /* display: block; */
  /* border: 2px solid red; */
  /* margin: auto; */
  /* margin-bottom: 3em; */

  h1 {
    /* display: inline-block; */
    margin: auto;
    color: white;
    font-weight: lighter;
    font-size: 3em;
    padding-bottom: 1em;
  }

  h2 {
    color: white;
    font-weight: lighter;
    font-size: 2em;
    font-weight: 200;
  }
  p3 {
    padding-top: 2.4em;
    /* display: inline-block; */
    margin: 0 0 auto;
    color: #c0bcbc;
    font-size: 2rem;
  }

  ${media.lessThan("medium")`
  /* flex:1 */

  `}
`
const Button = styled(Link)`
  display: inline-block;
  color: white;
  /* background-color: #e6e6e6; */
  font-size: 1em;
  font-weight: 400;
  margin: 1em;
  margin-top: 3em;
  padding: 0.25em 1em;
  border: 0.5px solid white;
  border-radius: 8px;
  box-shadow: 0px 1px 1px #292929;
  text-decoration: none;
  /* height: 40px; */
  min-width: 90px;
  padding: 10px;

  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  :hover {
    background-color: #3f3f3f;
    border-color: #a0a0a0;
  }
`
