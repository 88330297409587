import React from "react"
import { graphql } from "gatsby"
import { keyWords } from "../components/keywords"
import Layout from "../components/layout/layout.jsx"
import SEO from "../components/seo"

import Welcome from "../components/welcome"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" keywords={keyWords} />
    <Welcome
      leftImg={props.data.actImg}
      rightImg={props.data.finalTimesImg}
      lowerLeftImg={props.data.actImg}
      lowerRightImg={props.data.intervalSafeModeImg}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }

    welcomeLeftImg: file(relativePath: { eq: "single-test.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    welcomeRightImg: file(relativePath: { eq: "single-test.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    actImg: file(relativePath: { eq: "act.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    finalTimesImg: file(relativePath: { eq: "final-times.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    intervalSafeModeImg: file(relativePath: { eq: "interval-safe-mode.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
