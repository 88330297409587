import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import media from "styled-media-query"
import WelcomeDescription from "./welcome-text-description"
import WelcomeTitle from "./welcome-title"

const Welcome = ({ leftImg, rightImg, lowerLeftImg, lowerRightImg }) => {
  return (
    <>
      <PageContainer>
        <Desktop>
          <WelcomeRow>
            <PhoneImg>
              <Img
                fluid={leftImg.childImageSharp.fluid}
                alt="my image"
                className="image"
              />
            </PhoneImg>

            <WelcomeTitle />

            <PhoneImg>
              <Img
                fluid={rightImg.childImageSharp.fluid}
                alt="my image"
                className="image"
              />
            </PhoneImg>
          </WelcomeRow>
          <TextBlock>
            <WelcomeDescription />

            <br />

            <KeyFeatureImages>
              <Img
                fluid={lowerLeftImg.childImageSharp.fluid}
                alt="my image"
                className="keyImage"
              />

              <ul>
                <h3>Key Features</h3>
                <li>{`Unlimited Acts & Intervals`}</li>
                <li>{`Handles Show Stops`}</li>
                <li>{`Pre Show Call Notifications`}</li>
                <li>{`Interval Notifications`}</li>
                <li>{`Calculated Show Times`}</li>
                <li>{`Rounding Show Times`}</li>
                <li>{`Review Show Times of previous preformances.`}</li>
                <li>{`Share Times with a tap of a button!`}</li>
                <li>{`Runs in the background, even if the phone dies! - we'll carry on the show`}</li>
                <li>{`Keeps phone awake when running`}</li>
                <li>{`Interval Safe mode - keep your phone out during interval cues`}</li>
              </ul>

              <Img
                fluid={lowerRightImg.childImageSharp.fluid}
                alt="my image"
                className="keyImage"
              />
            </KeyFeatureImages>
          </TextBlock>
        </Desktop>

        <Mobile>
          <WelcomeRow>
            <WelcomeTitle />

            <SideBySidePhone>
              <Img
                fluid={leftImg.childImageSharp.fluid}
                alt="my image"
                className="image"
              />

              <Img
                fluid={rightImg.childImageSharp.fluid}
                alt="my image"
                className="image"
              />
            </SideBySidePhone>
          </WelcomeRow>

          <TextBlock>
            <WelcomeDescription />

            <br />
            <h3>Key Features</h3>

            <KeyFeatureImages>
              <ul>
                <li>{`Unlimited Acts & Intervals`}</li>
                <li>{`Handles Show Stops`}</li>
                <li>{`Pre Show Call Notifications`}</li>
                <li>{`Interval Notifications`}</li>
                <li>{`Calculated Show Times`}</li>
                <li>{`Rounding Show Times`}</li>
                <li>{`Review Show Times of previous preformances.`}</li>
                <li>{`Share Times with a tap of a button!`}</li>
                <li>{`Runs in the background, even if the phone dies! - we'll carry on the show`}</li>
                <li>{`Keeps phone awake when running`}</li>
                <li>{`Interval Safe mode - keep your phone out during interval cues`}</li>
              </ul>
              <SideBySidePhone>
                <Img
                  fluid={lowerRightImg.childImageSharp.fluid}
                  alt="my image"
                  className="keyImage"
                />
                <Img
                  fluid={lowerLeftImg.childImageSharp.fluid}
                  alt="my image"
                  className="keyImage"
                />
              </SideBySidePhone>
            </KeyFeatureImages>
          </TextBlock>
        </Mobile>
      </PageContainer>
    </>
  )
}

export default Welcome

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(47,3,78,1) 100%);
  /* background: linear-gradient(to bottom, #313030 0%, #5e5e5e 80%, #313030 100%); */
`
const Desktop = styled.div`
  display: none;
  ${media.greaterThan("medium")`
    display: block;
  `}
`

const Mobile = styled.div`
  display: block;
  ${media.greaterThan("medium")`
    display: none;
    /* height:200vh; */
    `}
`

const PhoneImg = styled.div`
  display: flex;
  flex-direction: row;
  /* flex-basis: flex-start;  */
  /* height: 90%; */
  /* width: 38%; */
  padding: 0;
  margin:0;
  /* background-color: blue; */
  flex: 1;
  justify-content:center ;

  .image {
    width: 80%;
    /* width:500px; */
   max-width: 450px;
   /* border:1px solid red; */
    
  }
`

const SideBySidePhone = styled.div`
  display: flex;
  /* flex-basis: flex-start; */
  /* background-color: pink; */
  width: 80%;
  /* flex: 2; */
  /* border: 1px solid red ; */

  .image {
    width: 60%;
    max-width: 500px ;
  }
`

const WelcomeRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: flex-start;
  align-items: center;
  justify-content: flex-end;
  text-align: center; 
  /* border: 1px solid red; */
  height: 95vh;


  ${media.lessThan("medium")`
  flex-direction:column;
  align-items:center;
  justify-content: flex-start;
  padding-top:2em
  `};
`

const TextBlock = styled.div`
  background-color: #2f034e;
  /* border: 1px solid green; */
  h1 {
    color: white;
    text-align: center;
    padding: 3em;
    padding-bottom: 0;
    font-weight: 150;

    ${media.lessThan("medium")`
  padding:1em;
  margin:1em;
  padding-bottom:0;
  `}
  }

  h3 {
    text-align: center;
    color: #ecebeb;
  }
  ul {
    margin-left: 15em;
    color: white;
    font-weight: 400;
    padding-bottom: 3em;
    /* border: 2px solid green; */
  }
`

const KeyFeatureImages = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid red; */

  ul {
    /* border: 1px solid green; */
    margin: 1em;
    padding: 0em;
  }

  .keyImage {
    width: 80%;
    max-width: 500px ;
  }

  ${media.lessThan("medium")`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;

  ul{
    padding-left:3em;
  }
  `}
`
