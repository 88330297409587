const keyWords = [
    'jack lord',
    'Show StopWatch',
    'Show Stop Watch',
    'Show Timming',
'Production Timer',
'Production Timming',
'Stage Manager Tools',
'Stage Manager Stop Watch',
'Stage Manager iOS App',
"show stop watch ios app",
"stage manager ios app",
"production timer for theatre",
"show timing app store",
"theatre timing app",
"timing calculations"
  ]
  
  export { keyWords }
  